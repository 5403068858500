import { staticLinks } from "../config/routingsLinks";
import MainPage from "../pages/MainPage/MainPage";
import MailPage from "../pages/MailPage/MailPage";
import PlanetPage from "../pages/PlanetPage/PlanetPage";
import GhostSchool from "../pages/GhostSchool/GhostSchool";
import Price from "../pages/PlanetPage/Price/Price";
import Keys from "../pages/Keys/Keys";
import Magazin from "../pages/Magazin/Magazin";


export const publicRoutes = [
  {
    path: staticLinks.main,
    element: <MainPage></MainPage>,
  },
  {
    path: staticLinks.mail,
    element: <MailPage></MailPage>,
  },
  {
    path: staticLinks.planet,
    element: <PlanetPage></PlanetPage>,
  },
  {
    path: staticLinks.ghost,
    element: <GhostSchool></GhostSchool>,
  },
  {
    path: staticLinks.price,
    element: <Price></Price>,
  },
  {
    path: staticLinks.keys,
    element: <Keys></Keys>,
  },
  {
    path: staticLinks.magazin,
    element: <Magazin></Magazin>,
  },
];
