import React from 'react'

import styles from './Keys.module.css'
import { staticLinks } from '../../config/routingsLinks';
import { Link } from 'react-router-dom';

const Keys = () => {
  return (
    <div className={styles.wrapper}>
      <img src="11111.jpg" />
      <a href="Klyuchi_Ot_19.08.23.docx">Книга Ключи.</a>
      <a href="Vehnii.docx">Книга Вечный Завет.</a>
      <Link to={staticLinks.planet}>Вернутся на главную.</Link>
    </div>
  );
}
export default Keys